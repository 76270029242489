import { FILE_RESOURCE_BASE_FRAGMENT } from '@/graphql/_Fragments/FileResource/Base';
import { VENUE_BASE_FRAGMENT } from '@/graphql/_Fragments/Venue/Base';

export const SUB_EDITION_CARD_FRAGMENT = `
  fragment subEditionCardFragment on SubEdition {
    id
    uid
    schemaCode
    name
    code
    archived
    startTime
    startTimestamp
    endTime
    endTimestamp
    logoFileResource {
      ...fileResourceBaseFragment
    }
    bannerFileResource {
      ...fileResourceBaseFragment
    }
    venue {
      ...venueBaseFragment
    }
    _isBookmarked(myUid: "%authUser%")
  }
  ${FILE_RESOURCE_BASE_FRAGMENT}
  ${VENUE_BASE_FRAGMENT}
`;
